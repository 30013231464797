import { render, staticRenderFns } from "./set_status_modal_trigger.vue?vue&type=template&id=1b08dbc4&"
import script from "./set_status_modal_trigger.vue?vue&type=script&lang=js&"
export * from "./set_status_modal_trigger.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../software/e4db08ccd194fdc0d4eb0bd795b0064a/parts/gitlab/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports