import { render, staticRenderFns } from "./single_stat.vue?vue&type=template&id=3e9d6fa6&"
import script from "./single_stat.vue?vue&type=script&lang=js&"
export * from "./single_stat.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../software/e4db08ccd194fdc0d4eb0bd795b0064a/parts/gitlab/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports